<template>
  <div class='full-width d-flex justify-between align-center'>
    <template v-if='isExistUser'>
      <DownloadAppScreen>
        <div class='auth-title'>
          {{ $t('congratulations') }}!
        </div>
        <div class='fz-16 lh-14' v-html='$t("inviteSuccessText")'></div>
      </DownloadAppScreen>
    </template>
    <template v-else>
      <RegistrationScreen
        v-if='activeStep === 1'
        @complete='handleCompleteRegistration'
      />
      <BuyPackageScreen
        v-if='activeStep === 2'
        @back='activeStep = 1'
        @complete='handleRedirect'
      />
    </template>
    <template v-if='showDownloadApps'>
      <DownloadAppScreen>
        <div class='auth-title'>
          {{ $t('congratulations') }}!
        </div>
        <div class='fz-16 lh-14' v-html='$t("inviteSuccessText")'></div>
      </DownloadAppScreen>
    </template>
  </div>
</template>
<script setup>
import BuyPackageScreen from './components/BuyPackageScreen.vue';
import DownloadAppScreen from './components/DownloadAppScreen.vue';
import RegistrationScreen from './components/RegistrationScreen.vue';

import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { useUser } from '@/composable/useUsers';

const i18n = useI18n();
const route = useRoute();
const router = useRouter();
const locale = i18n.locale.value;
const activeStep = ref(1);
const isExistUser = ref(false);
const { profile, getProfile } = useUser();
getProfile().then(() => {
  isExistUser.value = !!profile.value.nickname;
});

const showDownloadApps = ref(false);
const handleRedirect = async () => {
  isExistUser.value = true;
};

const handleCompleteRegistration = async (params) => {
  const { newPackage, currentPackage } = params;
  if (currentPackage === newPackage) {
    await handleRedirect();
  } else {
    await router.replace({ name: route.name, params: { locale: locale }, query: { package: newPackage } });
    activeStep.value++;
  }
};
</script>
